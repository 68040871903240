import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransAlert = createTrans({
  templateCreated: (
    <Trans
      i18nKey="alert.templateCreated"
      defaults="Notification template created."
    />
  ),
  templateUpdated: (
    <Trans
      i18nKey="alert.templateUpdated"
      defaults="Notification template updated."
    />
  ),
  templateRemoved: (
    <Trans
      i18nKey="alert.templateRemoved"
      defaults="Notification template removed."
    />
  ),
  templatesRemoved: (
    <Trans
      i18nKey="alert.templatesRemoved"
      defaults="Notification template(s) removed."
    />
  ),
});
