import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransField = createTrans({
  containsEmail: (
    <Trans i18nKey="field.containsEmail" defaults="Contains email" />
  ),
  containsPushNotification: (
    <Trans
      i18nKey="field.containsPushNotification"
      defaults="Contains push notification"
    />
  ),
  containsSms: <Trans i18nKey="field.containsSms" defaults="Contains SMS" />,
  empty: <Trans i18nKey="field.empty" defaults="empty" />,
  emailContent: <Trans i18nKey="field.emailContent" defaults="Email content" />,
  emailSubject: <Trans i18nKey="field.emailSubject" defaults="Email subject" />,
  fallbackLanguage: (
    <Trans i18nKey="field.fallbackLanguage" defaults="Fallback language" />
  ),
  fallback: <Trans i18nKey="field.fallback" defaults="fallback" />,
  languageNeedsToBeUnique: (
    <Trans
      i18nKey="field.languageNeedsToBeUnique"
      defaults="Language needs to be unique"
    />
  ),
  languages: <Trans i18nKey="field.languages" defaults="Languages" />,
  name: <Trans i18nKey="field.name" defaults="Name" />,
  owner: <Trans i18nKey="field.owner" defaults="Owner" />,
  preferredCustomerLevel: (
    <Trans
      i18nKey="field.preferredCustomerLevel"
      defaults={'Preferred customer level'}
    />
  ),
  pushNotificationContent: (
    <Trans
      i18nKey="field.pushNotificationContent"
      defaults="Push notification content"
    />
  ),
  pushNotificationTitle: (
    <Trans
      i18nKey="field.pushNotificationTitle"
      defaults="Push notification title"
    />
  ),
  required: <Trans i18nKey="field.required" defaults="Field is required" />,
  senderEmail: <Trans i18nKey="field.senderEmail" defaults="Sender email" />,
  senderName: <Trans i18nKey="field.senderName" defaults="Sender name" />,
  smsContent: <Trans i18nKey="field.smsContent" defaults="SMS content" />,
  type: <Trans i18nKey="field.type" defaults="Type" />,
  validFrom: <Trans i18nKey="field.validFrom" defaults="Valid from" />,
  validTo: <Trans i18nKey="field.validTo" defaults="Valid to" />,
});
