import { FC, useMemo } from 'react';
import { useCallback, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'store/utils';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { NotificationTemplatePayload } from 'dto/notificationTemplate';
import {
  getNotificationTemplateById,
  getNotificationTemplates,
  setNotificationTemplate,
  updateOrCreateNotificationTemplate,
} from 'features/notificationTemplate/notificationTemplateActions';
import {
  Button,
  FormProvider,
  PeriodField,
  SelectField,
  TextField,
  formSubmit,
  useForm,
  FormControl,
} from '@fleet/shared';
import { Grid, Stack, Typography } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { NotificationLanguageModalArrayField } from 'routes/notificationTemplates/notificationTemplateDetails/NotificationLanguageModalArrayField';
import { NotificationChannelsTable } from 'routes/notificationTemplates/notificationTemplateDetails/NotificationChannelsTable';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { mapNotificationTableToPayload } from 'helpers/notificationTemplate/mapper';
import { initialNotificationChannelData } from 'helpers/notificationTemplate/consts';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import {
  notificationTemplateSelector,
  notificationTemplatesFilterSelector,
} from 'features/notificationTemplate/notificationTemplateSelectors';
import { NotificationTemplateDetailsToolbar } from 'routes/notificationTemplates/notificationTemplateDetails/NotificationTemplateDetailsToolbar';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';
import { NotificationTemplateValidationModal } from 'routes/notificationTemplates/notificationTemplateDetails/validationModal/NotificationTemplateValidationModal';
import { useNotificationTemplatePayload } from 'hooks/useNotificationTemplatePayload';
import { useComponentWillUnmount } from 'hooks/useComponentWillUnmount';

const useStyles = makeStyles(
  () => ({
    searchContent: {
      padding: '8px 16px',
    },
  }),
  {
    name: 'NotificationTemplateDetailsForm',
  }
);

interface NotificationTemplateDetailsFormProps {}

export const NotificationTemplateDetailsForm: FC<NotificationTemplateDetailsFormProps> =
  () => {
    const classes = useStyles();
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const dispatch = useDispatch();
    const ownerId = useSelector(currentBusinessEntityIdSelector);
    const languages = useClassificationOptions(ClassificationGroup.CULTURE);
    const customerLevels = useClassificationOptions(
      ClassificationGroup.CUSTOMER_LEVEL
    );
    const notificationTypes = useClassificationOptions(
      ClassificationGroup.NOTIFICATION_TEMPLATE_TYPE
    );
    const businessEntities = useClassificationOptions(
      ClassificationGroup.BUSINESS_ENTITY
    );
    const notificationTemplate = useSelector(notificationTemplateSelector);
    const notificationTemplatesFilter = useSelector(
      notificationTemplatesFilterSelector
    );

    const alert = useAlert();

    useEffect(() => {
      if (id && !notificationTemplate?.id) {
        dispatch(getNotificationTemplateById(id));
      }
    }, [dispatch, id, notificationTemplate?.id]);

    useComponentWillUnmount(() => {
      dispatch(setNotificationTemplate(undefined));
    });

    const onSubmit = (
      notificationTemplatePayload: NotificationTemplatePayload
    ) =>
      formSubmit(async () => {
        (document.activeElement as HTMLInputElement)?.blur?.();

        const payload = {
          ...notificationTemplatePayload,
        };

        // This is to set the initial channel priority list if customer leaves it unchanged.
        if (!payload.notificationChannels) {
          payload.notificationChannels = mapNotificationTableToPayload(
            initialNotificationChannelData
          );
        }

        if (payload.notificationChannels.find((channel) => channel.isActive)) {
          payload.isActive = true;
        }

        const notificationTemplate = await dispatch(
          updateOrCreateNotificationTemplate(payload)
        ).unwrap();

        dispatch(setNotificationTemplate(notificationTemplate));
        dispatch(
          getNotificationTemplates({
            organizationId: ownerId,
            ...notificationTemplatesFilter,
          })
        );

        id
          ? alert.success(<TransAlert i18nKey="templateUpdated" />)
          : alert.success(<TransAlert i18nKey="templateCreated" />);

        if (!id && notificationTemplate.id) {
          history.push(
            `/notification-templates/edit/${notificationTemplate.id}`
          );
        }
      });

    const { initialValues, notificationTemplatePayload } =
      useNotificationTemplatePayload(
        notificationTemplate,
        ownerId,
        businessEntities
      );

    const { form, handleSubmit, values } = useForm<NotificationTemplatePayload>(
      {
        initialValues,
        onSubmit,
        subscription: { values: true },
      }
    );

    const fallbackLanguageOptions = useMemo(
      () =>
        languages.filter((lang) =>
          values.notificationsByLanguage?.some(
            (notification) => notification.languageId === lang.value
          )
        ),
      [languages, values.notificationsByLanguage]
    );

    const handleReset = useCallback(() => {
      form.reset();
    }, [form]);

    return (
      <div className={classes.searchContent}>
        <Grid
          container
          columns={2}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Grid item>
            <Typography variant="subtitle" fontWeight="700">
              <TransSubtitle i18nKey="details" />
            </Typography>
          </Grid>
          <NotificationTemplateDetailsToolbar />
        </Grid>

        <FormProvider {...form}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              sx={{ alignItems: 'flex-start' }}
              spacing={2}
              columns={5}
            >
              <Grid item xs={1}>
                <SelectField
                  name="organizationId"
                  label={<TransField i18nKey="owner" />}
                  options={businessEntities}
                  disabled
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  name="name"
                  label={<TransField i18nKey="name" />}
                  required
                />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="typeId"
                  required
                  label={<TransField i18nKey="type" />}
                  options={notificationTypes}
                />
              </Grid>
              <Grid item xs={1}>
                <NotificationLanguageModalArrayField />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="fallbackLanguageId"
                  required
                  label={<TransField i18nKey="fallbackLanguage" />}
                  options={fallbackLanguageOptions}
                />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="preferredCustomerLevelId"
                  required
                  label={<TransField i18nKey="preferredCustomerLevel" />}
                  options={customerLevels}
                />
              </Grid>
              <PeriodField
                from={{
                  name: 'validity.from',
                  label: <TransField i18nKey="validFrom" />,
                  required: true,
                }}
                to={{
                  name: 'validity.to',
                  label: <TransField i18nKey="validTo" />,
                  isClearable: true,
                }}
              />
              <Grid item xs={12} sx={{ marginTop: '16px' }}>
                <NotificationChannelsTable />
              </Grid>
              <Grid item xs="auto" sx={{ ml: 'auto' }}>
                <Stack direction="row" spacing={1}>
                  <FormControl label="&nbsp;">
                    <Button
                      sx={{ whiteSpace: 'nowrap' }}
                      variant="text"
                      onClick={handleReset}
                    >
                      <TransButton i18nKey="resetFields" />
                    </Button>
                  </FormControl>
                  <FormControl label="&nbsp;">
                    <Button variant="contained" type="submit" icon="check">
                      <TransButton i18nKey="save" />
                    </Button>
                  </FormControl>
                  {notificationTemplatePayload && (
                    <NotificationTemplateValidationModal
                      notificationTemplatePayload={notificationTemplatePayload}
                    />
                  )}
                </Stack>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </div>
    );
  };
