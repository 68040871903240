import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import { Button, CardContent, Stack, Typography } from '@mui/material';
import {
  ExternalLink,
  Icon,
  Loadable,
  SearchResult,
  Table,
  TableColumns,
  useIndeterminateRowSelectCheckbox,
} from '@fleet/shared';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { Link } from 'react-router-dom';
import { NotificationTemplateForTable } from 'dto/notificationTemplate';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { Row, usePagination, useRowSelect, useTable } from 'react-table';
import {
  notificationTemplatesFilterSelector,
  notificationTemplatesSelector,
} from 'features/notificationTemplate/notificationTemplateSelectors';
import { TransTableHead } from 'i18n/trans/tableHead';
import {
  deleteNotificationTemplates,
  getNotificationTemplates,
} from 'features/notificationTemplate/notificationTemplateActions';
import { notificationTemplatesTableLoadingSelector } from 'features/loading/loadingSelectors';
import { TransButton } from 'i18n/trans/button';
import { isEmpty } from 'lodash';
import { TransAlert } from 'i18n/trans/alert';
import { useAlert } from 'react-alert';

interface NotificationTemplatesTableProps {}

export const NotificationTemplatesTable: FC<NotificationTemplatesTableProps> =
  () => {
    const notificationTemplates = useSelector(notificationTemplatesSelector);
    const loading = useSelector(notificationTemplatesTableLoadingSelector);
    const notificationTemplatesFilter = useSelector(
      notificationTemplatesFilterSelector
    );
    const dispatch = useDispatch();
    const alert = useAlert();

    const getPage = useCallback(
      (pageSize: number) => {
        if (notificationTemplates) {
          const { limit = pageSize, offset } = notificationTemplates;
          return offset / limit;
        }
        return 0;
      },
      [notificationTemplates]
    );
    const data = useMemo(
      () => notificationTemplates?.items ?? [],
      [notificationTemplates]
    );

    const link = useCallback(
      (row: Row<NotificationTemplateForTable>) =>
        `/notification-templates/edit/${row.original.id}`,
      []
    );

    const columns = useMemo<TableColumns<NotificationTemplateForTable>>(
      () => [
        {
          accessor: 'name',
          Header: <TransTableHead i18nKey="name" />,
          Cell: ({ row }) => <Link to={link(row)}>{row.original.name}</Link>,
        },
        {
          id: 'organization.id',
          accessor: ({ organization }) => organization.name,
          Header: <TransTableHead i18nKey="owner" />,
          Cell: ({ row }: { row: Row<NotificationTemplateForTable> }) => (
            <ExternalLink
              content={row.original.organization.name}
              path={`/Contacts/Organization/Edit/${row.original.organization.id}`}
            />
          ),
        },
        {
          id: 'type.id',
          accessor: ({ type }) => type.name,
          Header: <TransTableHead i18nKey="type" />,
        },
        {
          id: 'languages.id',
          accessor: ({ languages }) =>
            languages.map((lang) => lang.name).join(', '),
          Header: <TransTableHead i18nKey="language" />,
        },
        {
          accessor: 'hasEmailContent',
          Header: <TransTableHead i18nKey="email" />,
          Cell: ({ row }: { row: Row<NotificationTemplateForTable> }) =>
            row.original.hasEmailContent && (
              <Icon name="check" color="success" />
            ),
        },
        {
          accessor: 'hasSmsContent',
          Header: <TransTableHead i18nKey="sms" />,
          Cell: ({ row }: { row: Row<NotificationTemplateForTable> }) =>
            row.original.hasSmsContent && <Icon name="check" color="success" />,
        },
        {
          accessor: 'hasPushContent',
          Header: <TransTableHead i18nKey="pushNotification" />,
          Cell: ({ row }: { row: Row<NotificationTemplateForTable> }) =>
            row.original.hasPushContent && (
              <Icon name="check" color="success" />
            ),
        },
      ],
      [link]
    );

    const handlePageChange = useCallback(
      async (paginationParams: PaginationParams) =>
        await dispatch(
          getNotificationTemplates({
            ...notificationTemplatesFilter,
            ...paginationParams,
          })
        ),
      [dispatch, notificationTemplatesFilter]
    );

    const table = useTable(
      {
        data,
        columns,
        pageCount: -1,
        useControlledState: (state) => ({
          ...state,
          pageIndex: getPage(state.pageSize),
        }),
        manualPagination: true,
        onPageChange: handlePageChange,
        total: notificationTemplates?.totalCount,
      },
      usePagination,
      useRowSelect,
      useIndeterminateRowSelectCheckbox
    );

    const handleBulkDelete = useCallback(async () => {
      await dispatch(
        deleteNotificationTemplates(
          table.selectedFlatRows.map((row) => row.original.id)
        )
      ).unwrap();

      alert.success(<TransAlert i18nKey="templatesRemoved" />);
      dispatch(getNotificationTemplates(notificationTemplatesFilter));
    }, [alert, table, dispatch, notificationTemplatesFilter]);

    return (
      <Loadable loading={loading}>
        <SearchResult results={data.length} loading={loading}>
          <Table
            caption={
              <>
                <CardContent sx={{ padding: '16px 24px' }}>
                  <Stack direction="row" alignItems="center">
                    <Typography variant="subtitle" fontWeight="700">
                      <TransSubtitle i18nKey="searchResults" />
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ ml: 2 }}
                    >
                      <TransSubtitle
                        i18nKey="notificationTemplatesQuantity"
                        values={{ num: data.length }}
                      />
                    </Typography>
                    <Button
                      disabled={isEmpty(table.selectedFlatRows)}
                      onClick={handleBulkDelete}
                      startIcon={<Icon name="trash" />}
                      sx={{ ml: 'auto', px: 1, py: 0 }}
                    >
                      <TransButton i18nKey="deleteSelected" />
                    </Button>
                  </Stack>
                </CardContent>
              </>
            }
            table={table}
          />
        </SearchResult>
      </Loadable>
    );
  };
