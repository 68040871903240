import type { FC } from 'react';
import { CardHeader, Layout, Loadable } from '@fleet/shared';
import { TransHeader } from 'i18n/trans/header';
import { Divider } from '@mui/material';
import { NotificationTemplateDetailsForm } from 'routes/notificationTemplates/notificationTemplateDetails/NotificationTemplateDetailsForm';
import { LanguageTabs } from 'routes/notificationTemplates/notificationTemplateDetails/languageTabs/LanguageTabs';
import React from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'store/utils';
import { notificationTemplateSelector } from 'features/notificationTemplate/notificationTemplateSelectors';
import { notificationTemplatesDetailsLoadingSelector } from 'features/loading/loadingSelectors';

interface NotificationTemplateDetailsProps {}

export const NotificationTemplateDetails: FC<NotificationTemplateDetailsProps> =
  () => {
    const { id } = useParams<{ id: string }>();
    const notificationTemplate = useSelector(notificationTemplateSelector);
    const loading = useSelector(notificationTemplatesDetailsLoadingSelector);

    return (
      <Loadable loading={loading} portal>
        <div>
          <Layout
            header={
              <CardHeader
                title={
                  <TransHeader
                    i18nKey="notificationTemplate"
                    values={{ name: `${notificationTemplate?.name ?? ''}` }}
                  />
                }
              />
            }
          >
            <>
              <NotificationTemplateDetailsForm />
              <Divider />
            </>
          </Layout>
        </div>
        <>{id && <LanguageTabs />}</>
      </Loadable>
    );
  };
