import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransSubtitle = createTrans({
  details: <Trans i18nKey="subtitle.details" defaults="Details" />,
  email: <Trans i18nKey="subtitle.email" defaults="Email" />,
  emailTemplate: (
    <Trans i18nKey="subtitle.emailTemplate" defaults="Email template" />
  ),
  notificationChannels: (
    <Trans
      i18nKey="subtitle.notificationChannels"
      defaults="Notification channels"
    />
  ),
  notificationTemplates: (
    <Trans
      i18nKey="subtitle.notifications.label"
      defaults="Notification templates"
    />
  ),
  pushNotificationTemplate: (
    <Trans
      i18nKey="subtitle.pushNotificationTemplate"
      defaults="Push notification template"
    />
  ),
  notificationTemplatesQuantity: (
    <Trans
      i18nKey="subtitle.notificationTemplatesQuantity"
      defaults="{{num}} notification templates"
    />
  ),
  pushNotification: (
    <Trans i18nKey="subtitle.pushNotification" defaults="Push notification" />
  ),
  search: <Trans i18nKey="subtitle.search" defaults="Search" />,
  searchResults: (
    <Trans i18nKey="subtitle.searchResults" defaults="Search results" />
  ),
  sms: <Trans i18nKey="subtitle.sms" defaults="SMS" />,
  smsTemplate: <Trans i18nKey="subtitle.smsTemplate" defaults="SMS template" />,
  deleteLanguage: (
    <Trans
      i18nKey="subtitle.deleteLanguage"
      defaults="Delete template language"
    />
  ),

  templateValidation: (
    <Trans
      i18nKey="subtitle.templateValidation"
      defaults="Template validation"
    />
  ),
});
