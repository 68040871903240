import type { FC } from 'react';
import { useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { EmailTemplate } from 'routes/notificationTemplates/notificationTemplateDetails/languageTabs/templateBodies/EmailTemplate';
import {
  Accordion,
  AccordionPanel,
  Button,
  FormProvider,
  formSubmit,
  useForm,
} from '@fleet/shared';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { NotificationTemplatePayload } from 'dto/notificationTemplate';
import { useDispatch, useSelector } from 'store/utils';
import { TransSubtitle } from 'i18n/trans/subtitle';
import {
  notificationTemplatesFilterSelector,
  selectNotificationTemplate,
} from 'features/notificationTemplate/notificationTemplateSelectors';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { TransButton } from 'i18n/trans/button';
import {
  getNotificationTemplates,
  setNotificationTemplate,
  updateOrCreateNotificationTemplate,
} from 'features/notificationTemplate/notificationTemplateActions';
import { SmsTemplate } from 'routes/notificationTemplates/notificationTemplateDetails/languageTabs/templateBodies/SmsTemplate';
import { PushNotificationTemplate } from 'routes/notificationTemplates/notificationTemplateDetails/languageTabs/templateBodies/PushNotificationTemplate';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { TransAlert } from 'i18n/trans/alert';
import { useAlert } from 'react-alert';
import { useNotificationTemplatePayload } from 'hooks/useNotificationTemplatePayload';

const useStyles = makeStyles(
  () => ({
    root: {
      padding: 0,
    },
    accordion: {
      '&.Mui-expanded': {
        margin: '0 !important',
      },
    },
  }),
  {
    name: 'LanguageTab',
  }
);

interface LanguageTabProps {
  tabIndex: number;
}

export const LanguageTab: FC<LanguageTabProps> = ({ tabIndex }) => {
  const classes = useStyles();
  const notificationTemplate = useSelector(selectNotificationTemplate);
  const notificationTemplatesFilter = useSelector(
    notificationTemplatesFilterSelector
  );

  const dispatch = useDispatch();
  const ownerId = useSelector(currentBusinessEntityIdSelector);
  const businessEntities = useClassificationOptions(
    ClassificationGroup.BUSINESS_ENTITY
  );
  const alert = useAlert();

  const onSubmit = (notificationTemplatePayload: NotificationTemplatePayload) =>
    formSubmit(async () => {
      (document.activeElement as HTMLInputElement)?.blur?.();

      const updatedTemplate = await dispatch(
        updateOrCreateNotificationTemplate(notificationTemplatePayload)
      ).unwrap();

      dispatch(setNotificationTemplate(updatedTemplate));
      dispatch(
        getNotificationTemplates({
          organizationId: ownerId,
          ...notificationTemplatesFilter,
        })
      );
      alert.success(<TransAlert i18nKey="templateUpdated" />);
    });

  const { initialValues } = useNotificationTemplatePayload(
    notificationTemplate,
    ownerId,
    businessEntities
  );

  const { form, handleSubmit } = useForm<NotificationTemplatePayload>({
    initialValues,
    onSubmit,
    subscription: { values: true },
  });

  const handleReset = useCallback(() => {
    form.reset();
  }, [form]);

  type templateBodyTypes = Array<'sms' | 'email' | 'pushNotification'>;
  const templateBody: templateBodyTypes = ['email', 'sms', 'pushNotification'];

  const renderBodyTemplate = (
    bodyType: 'sms' | 'email' | 'pushNotification'
  ) => {
    switch (bodyType) {
      case 'email':
        return <EmailTemplate index={tabIndex} />;
      case 'sms':
        return <SmsTemplate index={tabIndex} />;
      case 'pushNotification':
        return <PushNotificationTemplate index={tabIndex} />;
      default:
        return null;
    }
  };

  return (
    <div className={classes.root}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit}>
          {templateBody.map((bodyType) => {
            return (
              <Accordion key={bodyType}>
                <AccordionPanel
                  className={classes.accordion}
                  id={bodyType}
                  summary={
                    <Typography variant="subtitle">
                      <TransSubtitle i18nKey={bodyType} />
                    </Typography>
                  }
                >
                  <Box sx={{ paddingTop: 1 }}>
                    {renderBodyTemplate(bodyType)}
                  </Box>
                </AccordionPanel>
              </Accordion>
            );
          })}
          <Grid item xs={12} sx={{ display: 'flex', padding: '24px' }}>
            <Stack
              direction="row"
              sx={{ ml: 'auto', alignItems: 'center' }}
              spacing={1}
            >
              <Button onClick={handleReset} variant="text">
                <TransButton i18nKey="resetFields" />
              </Button>
              <Button icon="check" type="submit" sx={{ ml: 2 }}>
                <TransButton i18nKey="save" />
              </Button>
            </Stack>
          </Grid>
        </form>
      </FormProvider>
    </div>
  );
};
