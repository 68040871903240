import type { FC } from 'react';
import React, { useCallback } from 'react';
import { Grid } from '@mui/material';
import { Button, Icon } from '@fleet/shared';
import { TransButton } from 'i18n/trans/button';
import { deleteNotificationTemplateById } from 'features/notificationTemplate/notificationTemplateActions';
import { useDispatch, useSelector } from 'store/utils';
import { useHistory } from 'react-router-dom';
import { TransAlert } from 'i18n/trans/alert';
import { useAlert } from 'react-alert';
import { notificationTemplateSelector } from 'features/notificationTemplate/notificationTemplateSelectors';

interface NotificationTemplateDetailsToolbarProps {}

export const NotificationTemplateDetailsToolbar: FC<NotificationTemplateDetailsToolbarProps> =
  () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const alert = useAlert();
    const notificationTemplate = useSelector(notificationTemplateSelector);

    const onDelete = useCallback(async () => {
      if (notificationTemplate?.id) {
        await dispatch(
          deleteNotificationTemplateById(notificationTemplate.id)
        ).unwrap();
        alert.success(<TransAlert i18nKey="templateRemoved" />);
        history.replace('/notification-templates');
      }
    }, [dispatch, history, alert, notificationTemplate]);

    if (!notificationTemplate?.id) {
      return null;
    }

    return (
      <Grid item>
        <Button
          disabled
          variant="text"
          size="small"
          startIcon={<Icon name="question" />}
        >
          <TransButton i18nKey="help" />
        </Button>
        <Button
          variant="text"
          size="small"
          startIcon={<Icon name="add" />}
          disabled
        >
          <TransButton i18nKey="createNotification" />
        </Button>
        <Button
          disabled
          variant="text"
          size="small"
          startIcon={<Icon name="duplicate" />}
        >
          <TransButton i18nKey="copy" />
        </Button>
        <Button
          onClick={onDelete}
          variant="text"
          size="small"
          color="error"
          startIcon={<Icon name="delete" />}
        >
          <TransButton i18nKey="delete" />
        </Button>
      </Grid>
    );
  };
