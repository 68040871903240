import { type FC } from 'react';
import { NotificationTemplatesSearchForm } from 'routes/notificationTemplates/NotificationTemplatesSearchForm';
import { AddButton, CardHeader, Layout } from '@fleet/shared';
import { TransButton } from 'i18n/trans/button';
import { TransHeader } from 'i18n/trans/header';
import { Divider } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { NotificationTemplatesTable } from 'routes/notificationTemplates/NotificationTemplatesTable';

interface NotificationTemplatesProps {}

export const NotificationTemplates: FC<NotificationTemplatesProps> = () => {
  const history = useHistory();

  return (
    <Layout
      header={
        <CardHeader title={<TransHeader i18nKey="notificationTemplates" />}>
          <AddButton
            sx={{ padding: 0 }}
            onClick={() => history.push('/notification-templates/create')}
          >
            <TransButton i18nKey="add" />
          </AddButton>
        </CardHeader>
      }
    >
      <>
        <NotificationTemplatesSearchForm />
        <Divider />
        <NotificationTemplatesTable />
      </>
    </Layout>
  );
};
