import {
  NotificationChannelColumn,
  NotificationChannelColumnPayload,
  NotificationTemplate,
  NotificationTemplatePayload,
} from 'dto/notificationTemplate';

export const mapNotificationTableToPayload = (
  notificationTableData: NotificationChannelColumn[]
): NotificationChannelColumnPayload[] => {
  return notificationTableData.map((d) => ({
    orderNumber: d.orderNumber,
    isActive: d.isActive,
    channelId: d.channel.id,
    sendEvenWhenHigherPriorityExists: d.sendEvenWhenHigherPriorityExists,
  }));
};

export const mapNotificationTemplateResponseToPayload = (
  notificationTemplate: NotificationTemplate,
  ownerId: number,
  businessEntities: { value: string | number; label: string }[]
): NotificationTemplatePayload => {
  return {
    id: notificationTemplate.id,
    name: notificationTemplate.name,
    isActive: notificationTemplate.isActive,
    fallbackLanguageId: notificationTemplate.fallbackLanguage.id,
    typeId: notificationTemplate.type?.id,
    preferredCustomerLevelId: notificationTemplate.preferredCustomerLevel?.id,
    validity: notificationTemplate.validity,
    organizationId: ownerId,
    notificationChannels:
      notificationTemplate.notificationChannels?.map(
        (channel: NotificationChannelColumn) => ({
          orderNumber: channel.orderNumber,
          isActive: channel.isActive,
          channelId: channel.channel?.id,
          sendEvenWhenHigherPriorityExists:
            channel.sendEvenWhenHigherPriorityExists,
        })
      ) || [],
    notificationsByLanguage:
      notificationTemplate.notificationsByLanguage?.map((lang) => ({
        ...lang,
        languageId: lang.language?.id,
        smsSenderName:
          lang.smsSenderName ||
          businessEntities.find((be) => be.value === ownerId)?.label,
      })) || [],
  };
};
